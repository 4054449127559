import classNames from 'classnames';
import React from 'react';
import { DEFAULTS } from '../../init';
import DownloadMessagesButton from '../messages/DownloadMessagesButton';

interface ISearchHeaderProps extends React.AllHTMLAttributes<HTMLDivElement> {
	numFound: number;
	searchTime?: number;
	withDownloadButton?: boolean;
	fileNameTemplate?: string;
}
const SearchHeader = ({
	numFound,
	withDownloadButton,
	searchTime,
	title = 'Знайдено повідомлень',
	children,
	className,
	fileNameTemplate,
}: ISearchHeaderProps) => (
	<div className={classNames('d-flex justify-content-between w-100', className)}>
		<div className="hstack gap-2 align-items-center">
			<div className="text-muted">
				{title}:<span className="mx-1">{numFound.toLocaleString(DEFAULTS.locale)}</span>
				{searchTime !== undefined && (
					<span title="Тривалість пошуку">
						({(searchTime / 1000).toLocaleString(DEFAULTS.locale, { maximumSignificantDigits: 2 })} с)
					</span>
				)}
			</div>
			{numFound !== 0 && withDownloadButton && <DownloadMessagesButton fileNameTemplate={fileNameTemplate} />}
		</div>
		{children}
	</div>
);

export default SearchHeader;
