import dayjs from 'dayjs';
import React, { useState } from 'react';
import { Button, Dropdown, DropdownButton } from 'react-bootstrap';
import { BsDownload } from 'react-icons/bs';
import { showSystemError } from '../../alerts';
import SearchWaiter from '../../components/searchWaiter';
import TextIcon from '../../components/textIcon';
import { DEFAULTS } from '../../init';
import { TDownloadExtension } from '../../interfaces/common';
import { selectAppStore, selectSearcherStore, useAppSelector } from '../../store';
import { downloadData, getDatasetAttr } from '../../utils';

const DOWNLOAD_FORMATS: TDownloadExtension[] = ['csv', 'json'];
// interface IDownloadMessagesButtonProps extends PropsFromRedux, React.AllHTMLAttributes<HTMLDivElement> {}
interface IDownloadMessagesButtonProps extends React.AllHTMLAttributes<HTMLDivElement> {
	fileNameTemplate?: string;
}
const DownloadMessagesButton = ({ className, fileNameTemplate }: IDownloadMessagesButtonProps) => {
	const { accessToken } = useAppSelector(selectAppStore);
	const { queryString } = useAppSelector(selectSearcherStore);
	const [downloading, setDownloading] = useState(false);

	const onDownloadClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
		const format = getDatasetAttr(event, '.dropdown-item', 'format') as TDownloadExtension;
		setDownloading(true);
		try {
			try {
				const response = await fetch(`api/search/download/${format}`, {
					method: 'POST',
					headers: {
						Authorization: `Bearer ${accessToken}`,
						'Content-Type': 'application/x-www-form-urlencoded',
					},
					body: queryString + `&locale=${DEFAULTS.locale}`,
				});
				if (!response.ok) {
					throw new Error(`${response.status} ${response.statusText}: ${await response.text()}`);
				}
				downloadData(
					await response.text(),
					((fileNameTemplate || 'messages_') + dayjs().format('YYYYMMDD_hhmmss')).replace(/\s+/g, '_'),
					format
				);
				// saveDocx(await response.blob());
			} catch (error) {
				showSystemError(error as Error);
			}
		} finally {
			setDownloading(false);
		}
	};

	return (
		<>
			<DropdownButton
				id="ddDownloadMessages"
				title={<TextIcon Icon={BsDownload} className="mt-n1" title="Завантажити повідомлення (1000 максимум)" />}
				size="sm"
				variant="link link-secondary p-0"
				className={className}
			>
				{DOWNLOAD_FORMATS.map((format) => (
					<Dropdown.Item key={format} as={Button} data-format={format} onClick={onDownloadClick}>
						Завантажити в форматі {format}
					</Dropdown.Item>
				))}
			</DropdownButton>
			{downloading && <SearchWaiter title="Триває завантаження повідомлень" Icon={BsDownload} />}
		</>
	);
};

// const mapState = (state: RootState) => ({
// 	queryString: state.searcher.queryString,
// 	accessToken: state.app.accessToken,
// });

// const mapDispatch = {};

// const connector = connect(mapState, mapDispatch);
// type PropsFromRedux = ConnectedProps<typeof connector>;

// export default connector(DownloadMessagesButton);
export default DownloadMessagesButton;
