import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { BsGlobe } from 'react-icons/bs';
import { ConnectedProps, connect } from 'react-redux';
import Select, { ActionMeta, OptionProps, SingleValue, SingleValueProps, components } from 'react-select';
import { onSelectChangeSearchParam } from '../../features/app/appSlice';
import { DEFAULTS } from '../../init';
import { IDictionary, ISelectOption } from '../../interfaces/common';
import { ICountryDic } from '../../interfaces/dict';
import { RootState } from '../../store';
import CountryFlag from '../countryFlag';
import WithCountries, { IWithCountries } from '../hoc/withCountries';
import TextIcon from '../textIcon';

/**
 * Що показувати у виборі джерела:
 * - пошук: попередньо встановлені + будь-яка + пошук країни
 * - рубрика: попередньо встановлені + будь-яка + пошук країни
 * - фільтр джерел: попередньо встановлені + будь-яка + пошук країни
 * - редактор джерела: попередньо встановлені + пошук країни
 */
interface ICountryProps extends PropsFromRedux, IWithCountries {
	onCountryChange?: (value: SingleValue<ISelectOption>, actionMeta: ActionMeta<ISelectOption>) => void;
	country: string;
	showLabel?: boolean;
	showHelpText?: boolean;
	label?: string | JSX.Element;
	className?: string;
	containerClassName?: string;
	// Чи має бути пункт "будь-яка країна", що показує відсутність фільтру по полю
	withAnyCountry?: boolean;
	compact?: boolean;
}
const Country = ({
	country: digram,
	onCountryChange,
	showLabel = true,
	countriesById,
	countriesAllIds,
	onSelectChangeSearchParam,
	className,
	containerClassName,
	label = <TextIcon Icon={BsGlobe}>Країна джерел</TextIcon>,
	withAnyCountry = true,
	showHelpText = true,
	compact = true,
}: ICountryProps) => {
	const [countries, setCountries] = useState<ISelectOption[]>(() =>
		filterCountries(countriesAllIds, countriesById, digram, withAnyCountry, '')
	);

	const countryValue = useMemo(
		() => ({ label: countriesById[digram]?.title || 'будь-яка країна', value: digram }),
		[digram, countriesById]
	);

	useEffect(() => {
		setCountries(filterCountries(countriesAllIds, countriesById, digram, withAnyCountry, ''));
	}, [countriesAllIds, countriesById, digram, withAnyCountry]);

	const onInputChange = (term: string) => {
		if (term.length === 1) return;
		setCountries(filterCountries(countriesAllIds, countriesById, digram, withAnyCountry, term));
	};

	return (
		<div title="Країна (для пошуку почніть вводити назву)" className={containerClassName}>
			{showLabel && (
				<label htmlFor="search_country" className={classNames('form-label', compact && 'mb-0')}>
					{label}
				</label>
			)}
			<Select
				// className="basic-single"
				classNamePrefix="themed-select"
				// classNames={{
				// 	control:
				// }}
				// inputId="search_country"
				isSearchable
				options={countries}
				value={countryValue}
				onChange={onCountryChange || onSelectChangeSearchParam}
				placeholder="країна..."
				name="country"
				components={{ SingleValue: SingleElement, Option: CountryOption }}
				// styles={countryStyles}
				noOptionsMessage={() => DEFAULTS.noMatch}
				// menuIsOpen
				className={className}
				onInputChange={onInputChange}
			/>
			{showHelpText && <div className="text-muted text-small">Для пошуку країни почніть вводити її назву</div>}
		</div>
	);
};

const mapState = (state: RootState) => ({});

const mapDispatch = {
	onSelectChangeSearchParam,
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(WithCountries(Country));

// const countryStyles: Partial<StylesConfig<ISelectOption, false, GroupBase<ISelectOption>>> = {
// 	control: (baseStyles) => ({
// 		...baseStyles,
// 		backgroundColor: 'var(--bs-body-bg)',
// 	}),
// 	singleValue: (baseStyles) => ({
// 		...baseStyles,
// 		color: 'var(--bs-body-color)',
// 	}),
// menuList: (baseStyles) => ({
// 	...baseStyles,
// 	zIndex: 2,
// 	// backgroundColor: 'var(--bs-body-bg)',
// }),
// };

const SingleElement = ({ children, data, ...props }: SingleValueProps<ISelectOption>) => (
	<components.SingleValue data={data} {...props} className="d-flex align-items-center">
		<CountrySingleValue country={data.value}>{children}</CountrySingleValue>
	</components.SingleValue>
);

const CountryOption = ({ children, data, ...props }: OptionProps<ISelectOption, false>) => (
	<components.Option data={data} {...props} className="d-flex align-items-center">
		<CountrySingleValue country={data.value}>{children}</CountrySingleValue>
	</components.Option>
);

interface CountrySingleValueProps extends React.BaseHTMLAttributes<HTMLDivElement> {
	country: string;
}
const CountrySingleValue = ({ country, children }: CountrySingleValueProps) => (
	<>
		<CountryFlag country={country} className="me-2" />
		{children}
	</>
);

const filterCountries = (
	countriesAllIds: string[],
	countriesById: IDictionary<ICountryDic>,
	selected_digram: string,
	withAnyCountry: boolean,
	term: string
) => {
	const INITIAL_COUNTRIES = (withAnyCountry ? [''] : []).concat(['UA', 'RU', selected_digram]);

	const lowerTerm = term.toLowerCase();
	const resultIds = new Set(
		lowerTerm
			? countriesAllIds.filter((digram) => countriesById[digram]?.title.toLowerCase().includes(lowerTerm))
			: INITIAL_COUNTRIES
	);
	return [...resultIds].map((digram) => ({ label: countriesById[digram]?.title || 'будь-яка країна', value: digram }));
};
