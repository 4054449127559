import dayjs from 'dayjs';
import { DEFAULTS, TStringWithUndefined, USER_GMT } from '../init';
import { ISelectOption, TObjectType } from './common';

/**
 * * Здесь параметры осознанно сделаны строковыми для удобства работы с ними посредством URLSearchParams
 * * snake_case здесь для обратной совместимости
 */
export interface ISearchParams extends TObjectType {
	term: string;
	severity: string;
	period_index: string;
	start_date: TStringWithUndefined;
	end_date: TStringWithUndefined;
	sort_index: string;
	country: string;
	// Список идентификаторов, разделенных пробелом
	sources: string;
	rubric: string;
}
export const DEFAULT_SEARCH_PARAMS: ISearchParams = {
	term: '',
	severity: '75',
	period_index: '1',
	sort_index: '0',
	country: '',
	start_date: undefined,
	end_date: undefined,
	sources: '',
	rubric: '',
};

interface ISeverityStatus {
	value?: string;
	hint?: string;
}
export type TSeverityVisibilityStatus = 'D' | 'N' | '0';
export const SEVERITY_VISIBILITY_STATUS: { [key in TSeverityVisibilityStatus]: ISeverityStatus } = {
	D: {},
	N: {
		value: 'н/д',
		hint: 'При використанні логічних операторів параметр "строгість" не може бути застосований (див. довідку)',
	},
	// '1': {
	// 	value: '100',
	// 	hint: 'Застосована максимальна строгість у зв’язку з тим, обрано сортування не за релевантністю (див. довідку).',
	// },
	'0': {
		value: '0',
		hint: 'Застосована мінімальна строгість у зв’язку з тим, що в пошуковому рядку використано символи підстановки (* або ?)',
	},
};

export type TMessageListOrder = 'D' | 'R';
interface ISortSelectOption extends ISelectOption {
	messageOrder: TMessageListOrder;
}
export const SORT_OPTIONS: ISortSelectOption[] = [
	{
		label: 'за релевантністю',
		value: 'score desc, hit_date desc, id asc',
		messageOrder: 'D',
	},
	{
		label: 'в хронологічному порядку',
		value: 'hit_date asc, id asc',
		messageOrder: 'D',
	},
	{
		label: 'в зворотному хронологічному порядку',
		value: 'hit_date desc, id asc',
		messageOrder: 'R',
	},
];
export const SORT_INDEX_SCORE_DESC = '0';
export const SORT_INDEX_HIT_DATE_DESC = '2';

export interface IPeriodOption {
	label: string;
	start: () => string;
	// start: string;
	end?: string;
	gap?: string;
}
export const PERIOD_OPTIONS: IPeriodOption[] = [
	{
		label: 'будь-коли',
		// value: '',
		start: () => '',
		gap: '+1YEAR',
	},
	{
		label: 'сьогодні',
		// value: '[NOW/DAY TO *]',
		start: () => 'NOW/DAY',
		gap: '+1HOUR',
	},
	{
		label: 'поточного тижня',
		start: () => dayjs().startOf('week').toISOString(),
	},
	{
		label: 'за останні 7 днів',
		// value: '[NOW-7DAY/DAY TO *]',
		start: () => dayjs().subtract(7, 'days').startOf('day').toISOString(),
	},
	{
		label: 'поточного місяця',
		// value: '[NOW/MONTH TO *]',
		// start: 'NOW/MONTH' + HIT_DATE_OFFSET,
		start: () => dayjs().startOf('month').toISOString(),
	},
	{
		label: 'за останні 30 днів',
		// value: '[NOW-1MONTH/DAY TO *]',
		// start: 'NOW-1MONTH/DAY' + HIT_DATE_OFFSET,
		start: () => dayjs().subtract(30, 'days').startOf('day').toISOString(),
	},
	{
		label: 'за останні 90 днів',
		// value: '[NOW-3MONTH/DAY TO *]',
		// start: 'NOW-3MONTH/DAY',
		start: () => dayjs().subtract(90, 'days').startOf('day').toISOString(),
		gap: '+7DAY',
	},
	{
		label: 'поточного року',
		// value: '[NOW/YEAR TO *]',
		// start: 'NOW/YEAR',
		start: () => dayjs().startOf('year').toISOString(),
		gap: '+1MONTH',
	},
	{
		label: 'між вказаними датами',
		// value: '',
		start: () => '',
	},
];

export interface IGetMessagesListParamsProps extends Record<string, any> {
	sourceList: string;
	startDate?: string;
	endDate?: string;
	countries?: string;
	rows?: number;
	withoutBody?: boolean;
	len?: number;
}
export const getMessagesListParams = ({
	sourceList,
	startDate = 'NOW-1DAY',
	endDate = '*',
	countries,
	rows = DEFAULTS.messagesPerPage,
	withoutBody = false,
	len,
	...rest
}: IGetMessagesListParamsProps) => {
	const params = new URLSearchParams({
		...rest,
		TZ: USER_GMT,
		sort: 'hit_date desc, id asc',
		sources: `source_id:(${sourceList})`,
		period: `hit_date:[${startDate} TO ${endDate}]`,
		no_hl: 'On',
		rows: rows.toString(),
	});
	if (len) params.append('len', len.toString());
	if (countries) params.append('country', `country:(${countries})`);
	if (withoutBody) params.append('no_body', 'On');
	return params.toString();
};
